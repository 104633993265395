@import "./bootstrap/customizations.scss";

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

@import "./bootstrap/form-validation-extensions.scss";
@import "./bootstrap/bootstrap-social.scss";

@import "_variables.scss";
@import "_multiselect.scss";
@import "_forms.scss";

html {
    position: relative;
    min-height: 100%;
}

body {
    max-width: 100%;
    overflow-x: hidden;
    font-size: 14px;
    font-family: "Open Sans" !important;

    .footer {
        font-size: 11px;
        background-color: #fff;
        color: #bec0c3;
        height: 40px;
        line-height: 40px;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.background-student {
    background-color: #f7f7f7 !important;
}

.move-footer {
    padding-bottom: 40px;
}

//Headers
h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: 3rem;
        margin-bottom: 1rem;

        &.card-title {
            margin-top: 2rem;
        }
    }
}

h2 {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.page-title {
    margin: 30px 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.navbar-dark {
    background-color: #41218a;

    .navbar-brand img {
        display: block;
        width: 1.8rem;
    }

    .navbar-nav {
        .dropdown .dropdown-toggle {
            outline: none;
        }
    }
}

a {
    text-decoration: none !important;
}

.md-account {
    left: 90px !important;
    top: 250px !important;
    max-height: none !important;
    z-index: 1000 !important;

    a {
        color: #212529 !important;
    }

    .md-list {
        background: white;
    }

    .md-list-item-content {
        justify-content: center !important;
    }

    .profile {
        .md-list-item-content {
            justify-content: left !important;
        }
    }

    .admin-title, .sub-title {
        color: #388e3c;
        background-color: #fafafa;
        text-align: center;
        cursor: initial;
        border: none;

        .text {
            font-size: 14px !important;
            line-height: 14px;
        }
    }

    .text-normal {
        border: none;
        font-size: 15px;

        &:hover {
            background-color: rgba(158,158,158,0.2);
            cursor: pointer;
        }
    }

    .sub-title {
        border-top: 1px solid rgba(0,0,0,0.12);
    }
}

.modal-content {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.auth-wrap {
    margin: 0 auto;
    max-width: 370px;

    .logo {
        display: block;
        margin: 3rem auto;
        width: 5rem;
    }

    .card-header {
        text-align: center;
        background: none;
        border-bottom: none;
        padding: 1rem 0 0 0;
    }

    .btn-social {
        border-color: transparent;
    }

    .bottom-text {
        text-align: center;
        margin-top: 2rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: 5rem;

        .card-body {
            padding: 30px;

            .buttons {
                margin: 2rem auto 0 auto;
                width: 230px;

                .btn-block {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.or {
    display: block;
    position: relative;
    border-top: 1px solid #dfdfdf;
    margin: 30px 0;

    &:before {
        display: inline-block;
        content: "OR";
        color: #666666;
        background-color: #ffffff;
        position: absolute;
        text-align: center;
        top: -13px;
        width: 40px;
        left: 50%;
        margin-left: -20px;
    }
}

.page-header {
    margin: 2rem 0;

    h1 {
        margin: 0 0 1rem 0;
    }

    @include media-breakpoint-up(sm) {
        margin: 3rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }
    }
}

.table {
    td {
        vertical-align: middle;
    }

    .actions-column {
        width: 100px;
    }
}

.dataTables_wrapper {
    position: relative;

    .dataTables_processing {
        z-index: 1;
    }

    .dataTable {
        border-collapse: collapse !important;

        .actions {
            width: 100px;
        }
    }

    .dataTables_info {
        @include media-breakpoint-up(sm) {
            text-align: left !important;
        }
    }

    .pagination {
        margin: 0 !important;
        padding-top: 0.7em;

        @include media-breakpoint-down(xs) {
            justify-content: center !important;
        }
    }
}

.validation-summary-errors {
    .close {
        position: relative;
        top: -2px;
        right: -21px;
        line-height: 18px;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
}

.v-sidebar-menu {
    .logo {
        background-image: url(/images/rti-stored-logo5.png);
        background-repeat: no-repeat;
        background-size: contain;
        height: 65px;
        width: 65px;
        border-radius: 3px;
        margin: 0 auto;
        margin-bottom: 45px;
        margin-top: 15px;
    }

    background-color: #5F4975 !important;

    .vsm--item {
        width: 50px !important;
        height: 50px;
        margin: 0 6px;
        margin-bottom: 10px;
    }

    .vsm--link {
        padding: 0 !important;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .vsm--link_level-1 {
        .vsm--icon {
            background-color: transparent !important;
            font-size: 25px !important;
            margin: 0 !important;
            color: white !important;

            &:before {
                vertical-align: sub;
            }
        }

        &.vsm--link_active {
            box-shadow: none !important;

            .vsm--icon {
                background-color: #FFF !important;
                border-radius: 50% !important;
                height: 40px !important;
                width: 40px !important;
                color: #5F4975 !important;
                padding: 5px 8px;
            }
        }
    }
}

.vsm--mobile-bg {
    background-color: transparent !important;
}

.backdrop {
    content: "";
    background: rgba(0,0,0,0.65);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.open-side {
    padding-left: 65px !important;
    max-width: 350px !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 999;
    box-sizing: border-box;
    width: 100%;
    height: 100% !important;
    text-align: left;


    .vsm--item {
        width: 100% !important;
    }

    .vsm--link {
        color: rgba(0, 0, 0, 0.87) !important;

        :hover {
            color: #23527c !important;
            background-color: rgba(158,158,158,0.2) !important;
        }
    }

    .vsm--list {
        background-color: white !important;
    }

    .vsm--item_open {
        background-color: white;
        height: 100% !important;
    }

    .vsm--dropdown {
        max-height: none !important;
        height: 100% !important;
    }

    .title-sidebar {
        text-align: center;
        color: rgba(0, 0, 0, 0.87) !important;
        padding: 8px;
        font-size: 24px;
    }

    .subtitle-sidebar {
        font-size: 18px;
    }

    .vsm--dropdown {
        padding: 8px;

        .vsm--title {
            padding-left: 8px;
            padding-right: 8px;
            text-transform: capitalize;
            font-size: 16px;
            text-align: left;
        }

        .vsm--list {
            .vsm--item {
                min-height: 48px;
                height: auto;
                margin: 0;
            }
        }
    }
}

.md-icon {
    transition: color .4s cubic-bezier(.4,0,.2,1);
    font-family: Material Icons;
    font-feature-settings: "liga";
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
    text-rendering: optimizeLegibility;
    text-transform: none;
    word-wrap: normal;
    white-space: nowrap;
    width: 24px;
    min-width: 24px;
    height: 24px;
    font-size: 24px !important;
    margin: auto;
    display: inline-flex;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.modal-header {
    background-color: $primary;
    color: $white;
}

.primary-nav {
    .nav-link {
        &.active {
            border-bottom-color: $primary;
            border-bottom-width: 2px;
            color: $dark;
        }
    }
}

.btn.disabled, .btn:disabled {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #8b8b8b;
    opacity: 1;
}

.modal-dialog {
    .modal-footer {
        button {
            min-width: 85px;
        }
    }
}

//Need discuss

.move-content {
    padding-left: 75px;
}

.btn {
    &.btn-link {
        &.rti-link {
            font-weight: 700;
            font-size: 14px;
            color: #000;
            min-width: 200px;
            text-align: left;
            padding: 5px;

            &:hover, &:focus {
                text-decoration: none;
                background-color: #f2f7fd;
            }

            .material-icons {
                color: $secondary;
                vertical-align: bottom;
                font-size: 22px;
                padding-right: 5px;

                &.red-icon {
                    color: #ff5f5f;
                }
            }

            &:disabled {
                background-color: transparent;
                border: none;
            }

            &.width-auto {
                min-width: auto;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rti-spinner {
    margin-top: 3rem;
    text-align: center;

    .spinner-border {
        width: 3rem;
        height: 3rem;
    }
}

.form-control {
    &.rti-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #ced4da;
    }
}

.modal-without-body {
    .modal-footer, .modal-header {
        display: none;
    }

    .modal-content {
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
}

.datatable-custom-processing {
    .dataTables_wrapper {
        .dataTables_processing {
            position: absolute;
            top: 0;
            height: 100%;
            background-color: #FFF;
            opacity: 0.8;
            width: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            border: 0;
        }
    }
}
