﻿$tag-margin: 3px;

.multiselect {
    color: $input-color;
    min-height: unset;

    .multiselect__select {
        width: 33px;

        &:before {
            color: $custom-select-color;
            border-color: $custom-select-color transparent transparent;
        }
    }

    .multiselect__tags {
        border-color: $input-border-color;
        border-radius: $input-border-radius;
        font-size: $input-font-size;
        padding: $input-padding-y 40px $input-padding-y $input-padding-x;
        min-height: unset;

        .multiselect__tag {
            background-color: $primary;
            margin-top: $tag-margin;
            margin-bottom: -$tag-margin;

            &-icon {
                &:after {
                    color: $light;
                }

                &:hover {
                    background: darken($primary, 5%);
                }
            }
        }

        .multiselect__placeholder {
            margin-bottom: 0;
            color: $input-placeholder-color;
            padding: 0;
            cursor: default;
        }

        .multiselect__input, .multiselect__single {
            line-height: $input-line-height;
            padding: 0;
            margin-bottom: 0;
        }

        .multiselect__single {
            cursor: default;
        }
    }

    .multiselect__option--highlight {
        /*background-color: $primary;*/
    }
}

.multiselect {
    &.sm {
        font-size: $input-font-size-sm;

        .multiselect__select {
            height: $input-height-sm;
            line-height: calc(#{$input-height-sm} / 2 + 2px);
        }

        .multiselect__tags {
            border-radius: $input-border-radius-sm;
            font-size: $input-font-size-sm;
            padding: $input-padding-y-sm 40px $input-padding-y-sm $input-padding-x-sm;
        }

        .multiselect__input, .multiselect__single {
            font-size: $input-font-size-sm;
            line-height: $input-line-height-sm;
        }
    }
}

.intervention-table {
    .multiselect {
        .multiselect__tags {
            .multiselect__tag {
                background-color: $light;
                color: $dark;
                border: 1px solid $input-border-color;

                &-icon {
                    &:after {
                        color: $dark;
                    }

                    &:hover {
                        background: darken($light, 10%);
                    }
                }
            }
        }
    }
}
