@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_variables.scss";

.date-range-picker {
    width: 100%;

    .form-control {
        position: relative;

        .link-clear {
            position: absolute;
            color: $custom-select-color;
            top: 0;
            right: 0.7rem;
            height: 100%;
            display: flex;
            align-items: center;

            .oi {
                top: 0;
                font-size: 70%;
                transform: rotate(45deg);
            }

            @include hover {
                color: lighten($body-color, 20%);
                text-decoration: none;
            }
        }
    }

    .placeholder {
        color: $text-muted;
    }

    .daterangepicker {
        .ranges {
            padding-left: 0;
            padding-right: 0;

            li {
                outline: 0;
            }
        }

        .drp-buttons {
            .btn-clear {
                float: left;
                margin: 0;
            }
        }
    }
}
