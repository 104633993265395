﻿//Refer to:
//https://github.com/twbs/bootstrap/tree/v4.6.0/scss

// Color system
$primary: #388e3c;
$secondary: #4b93e7;
$danger: #ff5f5f;

// Components
$border-radius: .15rem;
$border-radius-lg: .2rem;
$border-radius-sm: .15rem;

// Navbar
$navbar-dark-color: rgba(255,255,255,0.75);
$navbar-dark-hover-color: #fff;
$navbar-dark-active-color: #fff;

// Modals
$modal-backdrop-bg: #fbfbfb;

// Pagination
$pagination-padding-y: .25rem;
$pagination-padding-x: .625rem;